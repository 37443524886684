<template>
    <d-modal v-if="modal.open" @close="closeHandler" size="lg">
        <d-modal-header>
            <d-modal-title>Exterieur 360</d-modal-title>
        </d-modal-header>
        <d-modal-body>
            <viewer-exterior360
                ref="exterior360"
                base-url="http://192.168.35.7:81"
                :width="730"
                path-prefix="photos/photostudio/upload/exterior"
                :license-plate="modal.vehicle.licenseplate"
            />
        </d-modal-body>
    </d-modal>
</template>

<script>
import ViewerExterior360 from '../components/ViewerExterior360'
export default {
    components: {
        ViewerExterior360
    },
    props: {
        modal: {
            required: true
        }
    },
    data() {
        return {
        }
    },
    methods: {
        closeHandler() {
            this.modal.open = false
            this.$emit('closeHandler', this.modal.open)
        }
    },
}
</script>
