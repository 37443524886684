<template>
    <div>
        <d-button @click="testCaptureHandler">
            <i class="material-icons">bug_report</i> test camera's
        </d-button>
        <loader v-show="showLoader" :message="loadingMessage"/>
    </div>
</template>

<script>
import loader from "@/modules/photoStudio/components/loader";
import {testCapture} from "@/modules/photoStudio/requests/photostudio.request";

export default {
    components: {
        loader
    },
    data() {
        return {
            showLoader: false,
            loadingMessage: null
        }
    },
    methods: {
        async testCaptureHandler() {
            this.showLoader = true
            this.loadingMessage = "Test word uitgevoerd"

            try {
                await testCapture()
                this.loadingMessage = "Test gelukt"
            } catch (e) {
                this.loadingMessage = "Er is iets fouts gegaan"
            } finally {
                setTimeout(() => {
                    this.showLoader = false
                    this.loadingMessage = ""
                }, 2500)
            }
        }
    }
}
</script>
