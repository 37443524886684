<template>
    <div class="loader-backdrop">
        <div>
            <div>
                <div class="loader-dual-ring"></div>
                <h3>{{ message }}</h3>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "loader",
    props: {
        message: {
            required: true
        }
    }
}
</script>

<style lang="scss">
.loader-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba(255, 255, 255, 0.70);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;

    > div {
        display: flex;
        align-items: center;

        > div {
            display: flex;
            flex-direction: column;

            h3 {
                margin-top: 20px;
                text-align: center;
            }
        }
    }
}

.loader-dual-ring {
    display: inline-block;
    width: 120px;
    height: 120px;
    margin: 0 auto;
}

.loader-dual-ring:after {
    content: " ";
    display: block;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 6px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: loader-dual-ring 1.2s linear infinite;
}

@keyframes loader-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

</style>
