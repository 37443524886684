<template>
    <d-modal v-if="modal.open" @close="closeHandler">
        <d-modal-header>
            <d-modal-title>Platform opnieuw opstarten</d-modal-title>
        </d-modal-header>
        <d-modal-body>
            <h3>Belangrijk: Loop eerst de stappen door voordat je op Herstarten drukt!</h3>
            <h4>Stappen</h4>
            <ol>
                <li>Herstart de fotostudio computer (De computer waar de camera's op aangesloten zitten, in de blauwe schuifbak)</li>
                <li>Haal de USB's van beide camera's uit de computer en stop deze er dan weer in terug</li>
                <li>Druk nu op "Herstarten"</li>
            </ol>
            <button class="btn btn-success pull-right" @click="restartPlc">
                <i class="material-icons">restart_alt</i> Opstarten
            </button>
            <button class="btn btn-primary" style="float: right" @click="closeHandler">
                <i class="material-icons">arrow_left</i> Laat maar, breng me terug
            </button>
        </d-modal-body>
        <loader v-show="showLoader" :message="loadingMessage"/>
    </d-modal>
</template>

<script>

import loader from "@/modules/photoStudio/components/loader";
import {restartPlc} from "@/modules/photoStudio/requests/photostudio.request";

export default {
    components: {
        loader, restartPlc
    },
    props: {
        modal: {
            required: true
        }
    },
    data() {
        return {
            showLoader: false,
            loadingMessage: null,
        }
    },
    methods: {
        closeHandler() {
            this.modal.open = false
            this.$emit('closeHandler', this.modal.open)
        },
        async restartPlc() {
            try {
                try {
                    this.showLoader = true
                    this.loadingMessage = "Opdracht word verzonden.."
                    this.loadingMessage = await restartPlc()
                } catch (e) {
                    this.loadingMessage = "Er is iets fouts gegaan"
                } finally {
                    setTimeout(() => {
                        this.showLoader = false
                        this.loadingMessage = ""
                        this.$emit('confirmed')
                        this.closeHandler()
                    }, 5000)
                }
            } catch (e) {
            }
        }
    }
}
</script>
