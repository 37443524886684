<template>
    <div>
        <d-button @click="restartSortingService">
            <i class="material-icons">restart_alt</i> Druk hier als je de foto's niet kunt zien
        </d-button>
        <loader v-show="showLoader" :message="loadingMessage"/>
    </div>
</template>

<script>
import loader from "@/modules/photoStudio/components/loader";
import {restartSortingService} from "@/modules/photoStudio/requests/photostudio.request";
export default {
    components: {
        loader,
        restartSortingService
    },
    data() {
        return {
            showLoader: false,
            loadingMessage: null,
        }
    },
    methods: {
        async restartSortingService() {
            this.showLoader = true
            this.loadingMessage = "Opdracht word verzonden.."

            try {
                await restartSortingService()
                this.loadingMessage = "Opdracht verzonden"
            } catch (e) {
                this.loadingMessage = "Er is iets fouts gegaan"
            } finally {
                setTimeout(() => {
                    this.showLoader = false
                    this.loadingMessage = ""
                }, 2500)
            }
        }
    }
}
</script>
