<template>
    <div>
        <d-button @click="restartPlcCall">
            <i class="material-icons">electrical_services</i> Druk hier als het platform niet meer werkt/draait
        </d-button>
        <loader v-show="showLoader" :message="loadingMessage"/>
        <restartPlcModal :modal="restartPlcConfirmModal"
                          @closeHandler="(e) => { restartPlcConfirmModal.open = e }"/>
    </div>
</template>

<script>
import loader from "@/modules/photoStudio/components/loader";
import restartPlcModal from './../modals/restartPlcModal'

export default {
    components: {
        loader, restartPlcModal
    },
    data() {
        return {
            showLoader: false,
            loadingMessage: null,
            restartPlcConfirmModal: {
                open: false,
                photo: {
                    id: null,
                    url: null
                }
            }
        }
    },
    methods: {
        async restartPlcCall() {
            this.restartPlcConfirmModal.open = true
        }
    }
}
</script>
