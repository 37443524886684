<template>
    <d-modal v-if="modal.open" @close="closeHandler">
        <d-modal-header>
            <d-modal-title>Start nieuw process</d-modal-title>
        </d-modal-header>
        <d-modal-body>
            <v-input label="Kenteken"
                     placeHolderText="Voorbeeld (19-TGS-2)"
                     v-model="form.licensePlate"
                     :show-errors="true"
                     :disabled="!canStartNewProcess"
                     :auto-complete-list="autoCompleteList"
                     auto-complete-key="licenseplate"
                     auto-complete-label="label"
                     ref="licensePlate"
                     v-on:keyup.enter="validate"
            />
            <button class="btn btn-primary" style="float: right" @click="validate" :disabled="isDisabled">
                <i class="material-icons">save</i>
                Start
            </button>
        </d-modal-body>
    </d-modal>
</template>

<script>
import {isLicensePlate} from "@/utils/validator";
import {mapGetters} from "vuex"
import _ from "lodash";
import {licensePlatesInProcess} from "@/modules/photoStudio/requests/photostudio.request";

export default {
    props: {
        modal: {
            required: true
        }
    },
    data() {
        return {
            form: {
                licensePlate: {
                    validations: {
                        isLicensePlate
                    }
                }
            },
            autoCompleteList: [],
            isDisabled: false
        }
    },
    computed: {
        ...mapGetters('photoStudio.studio', ['canStartNewProcess'])
    },
    methods: {
        validate() {
            this.isDisabled = true
            this.$v.validate(this.form, (async ({licensePlate}) => {
                await this.$store.dispatch('photoStudio.studio/addProcess', {licensePlate: licensePlate})
                this.isDisabled = false
                this.closeHandler()
            }), (error) => {
                console.log(error)
            })
        },
        closeHandler() {
            this.modal.open = false
            this.$emit('closeHandler', this.modal.open)
        },
        getAllLicensePlates: _.debounce(async function (value) {
            let data = await licensePlatesInProcess(value)
            this.autoCompleteList = []
            data.forEach((obj) => {
                this.autoCompleteList.push({licenseplate: obj.licenseplate, label: `${obj.licenseplate} - ${obj.make} ${obj.model}`})
            })
        }, 500)
    },
    watch: {
        'form.licensePlate.value': {
            handler(_new) {
                this.getAllLicensePlates(_new)
            }
        },
        'modal.open': {
            async handler(_new) {
                if (_new) {
                    this.form.licensePlate.value = null
                    setTimeout(function () {
                        this.$refs.licensePlate.$refs.input.focus()
                    }.bind(this), 5)
                }
            }
        }
    }
}
</script>
