<template>
    <div ref="360exteriorContainer" :style="getSize" class="pano-container">
        <canvas ref="360exterior"/>
    </div>
</template>

<script>
import Hammer from 'hammerjs'
import './padStart.polyfill'

export default {
    name: 'DViewer360Exterior',
    props: {
        width: {
            type: Number,
            required: false,
            default: 900
        },
        baseUrl: {
            type: String,
            required: true
        },
        pathPrefix: {
            type: String,
            required: false
        },
        licensePlate: {
            type: String,
            required: true
        },
        cameras: {
            type: Number,
            require: false,
            default: 2
        },
        imageCount: {
            type: Number,
            required: false,
            default: 18
        },
        isCloudfront: {
            type: Boolean,
            required: false,
            default: false
        },
        bucket: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            container: null,
            canvas: 0,
            context: 0,
            images: {
                low: {},
                ultra: {},
                200: {},
                400: {}
            },
            startImage: 1,
            currentType: 1,
            currentRow: 1,
            currentImage: 1,
            loadedImages: 0,
            zoom: 1,
            zooming: false,
            pinchzoom: 1,
            pinchTranslateX: 0,
            pinchTranslateY: 0,
            zoomCount: 0,
            translateX: 0,
            translateY: 0,
            dragging: false,
            dragImage: 0,
            dragX: 0,
            dragY: 0,
            dragTranslateX: 0,
            dragTranslateY: 0,
            hammerTime: null
        }
    },
    computed: {
        totalImages: {
            get() {
                return this.imageCount * this.cameras
            }
        },
        getSize: {
            get() {
                return {
                    width: this.width + 'px',
                    height: (this.width / 3 * 2) + 'px'
                }
            }
        }
    },
    watch: {
        width: {
            handler() {
                console.log('test')
                this.$redraw()
                this.resizeCanvas()
            }
        }
    },
    mounted() {
        this.container = this.$refs['360exteriorContainer']
        this.canvas = this.$refs['360exterior']

        this.setup()
    },
    methods: {
        setup() {
            window.addEventListener('mouseup', this.mouseUpEvent)
            window.addEventListener('mousemove', this.mouseMoveEvent)
            window.addEventListener('orientationchange', this.orientationChangeEvent, false)
            window.addEventListener('resize', this.resizeEvent, false)

            this.context = this.canvas.getContext('2d')
            this.preloadImages()

            this.hammerTime = new Hammer(this.canvas, {inputClass: Hammer.TouchInput})
            this.hammerTime.get('pan').set({direction: Hammer.DIRECTION_HORIZONTAL})
            this.hammerTime.get('pinch').set({enable: true})

            this.canvas.addEventListener('mousedown', this.mouseDownEvent)

            const mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? 'DOMMouseScroll' : 'mousewheel'
            if (this.canvas.attachEvent) {
                this.canvas.attachEvent('on' + mousewheelevt, this.mouseWheelEvent)
            } else if (this.canvas.addEventListener) {
                this.canvas.addEventListener(mousewheelevt, this.mouseWheelEvent, false)
            }

            this.hammerTime.on('panstart', this.panStartEvent)
            this.hammerTime.on('panmove', this.panMoveEvent)
            this.hammerTime.on('panend pancancel', this.panendPanCancelEvent)
            this.hammerTime.on('pinchstart', this.pinchStartEvent)
            this.hammerTime.on('pinchmove', this.pinchMoveEvent)
            this.hammerTime.on('pinchend pinchcancel', this.pinchendPinchCancelEvent)
        },
        // Events
        mouseUpEvent() {
            this.dragging = false
            this.redrawExterior()
        },
        mouseDownEvent(e) {
            this.dragging = true
            this.dragImage = this.currentImage
            this.dragX = e.pageX
            this.dragY = e.pageY
            this.dragTranslateX = this.translateX
            this.dragTranslateY = this.translateY
        },
        mouseMoveEvent(e) {
            if (this.dragging && this.zoom === 1) {
                const changeX = Math.floor((e.pageX - this.dragX) / 10) % this.imageCount
                let newImage

                if (changeX < 0) {
                    newImage = this.dragImage + Math.abs(changeX)
                } else {
                    newImage = this.dragImage - changeX
                }

                if (newImage === 0) {
                    newImage = this.imageCount
                } else if (newImage < 0) {
                    newImage = this.imageCount - Math.abs(newImage)
                } else if (newImage > this.imageCount) {
                    newImage = newImage - this.imageCount
                }

                this.currentImage = newImage

                this.redrawExterior()
            } else if (this.dragging && this.zoom > 1) {
                const changeX = this.dragX - e.pageX
                const changeY = this.dragY - e.pageY

                this.translateX = this.dragTranslateX - changeX
                this.translateY = this.dragTranslateY - changeY

                if (this.translateX > ((this.zoom - 1) * this.canvas.width) / 2) {
                    this.translateX = ((this.zoom - 1) * this.canvas.width) / 2
                } else if (this.translateX < 0 - ((this.zoom - 1) * this.canvas.width) / 2) {
                    this.translateX = 0 - ((this.zoom - 1) * this.canvas.width) / 2
                }

                if (this.translateY > ((this.zoom - 1) * this.canvas.height) / 2) {
                    this.translateY = ((this.zoom - 1) * this.canvas.height) / 2
                } else if (this.translateY < 0 - ((this.zoom - 1) * this.canvas.height) / 2) {
                    this.translateY = 0 - ((this.zoom - 1) * this.canvas.height) / 2
                }

                this.redrawExterior()
            }
        },
        mouseWheelEvent(e) {
            e.preventDefault()
            this.zoomCount++

            const delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)))
            this.zoom += delta

            if (this.zoom < 1) {
                this.zoom = 1
            } else if (this.zoom > 7) {
                this.zoom = 7
            }

            if (delta < 0) {
                if (this.zoom === 1) {
                    this.translateX = 0
                    this.translateY = 0
                } else {
                    this.translateX = this.translateX / 2
                    this.translateY = this.translateY / 2
                }
            }
            this.redrawExterior()
        },
        orientationChangeEvent() {
            this.redrawExterior()
        },
        resizeEvent() {
            this.redrawExterior()
        },
        // HammerJS Events
        panStartEvent(e) {
            e.preventDefault()

            this.dragging = true
            this.dragImage = this.currentImage
            this.dragX = e.pageX
            this.dragY = e.pageY
            this.dragTranslateX = this.translateX
            this.dragTranslateY = this.translateY
        },
        panMoveEvent(e) {
            e.preventDefault()
            if (this.dragging && this.zoom === 1) {
                const change = Math.floor(e.deltaX / 25) % this.imageCount
                let newImage

                if (change < 0) {
                    newImage = this.dragImage + Math.abs(change)
                } else {
                    newImage = this.dragImage - change
                }

                if (newImage === 0) {
                    newImage = this.imageCount
                } else if (newImage < 0) {
                    newImage = this.imageCount + newImage
                } else if (newImage > this.imageCount) {
                    newImage = newImage - this.imageCount
                }

                this.currentImage = newImage

                this.redrawExterior()
            } else if (this.dragging && this.zoom > 1) {
                this.translateX = this.dragTranslateX + e.deltaX
                this.translateY = this.dragTranslateY + e.deltaY

                if (this.translateX > ((this.zoom - 1) * this.canvas.width) / 2) {
                    this.translateX = ((this.zoom - 1) * this.canvas.width) / 2
                } else if (this.translateX < 0 - ((this.zoom - 1) * this.canvas.width) / 2) {
                    this.translateX = 0 - ((this.zoom - 1) * this.canvas.width) / 2
                }

                if (this.translateY > ((this.zoom - 1) * this.canvas.height) / 2) {
                    this.translateY = ((this.zoom - 1) * this.canvas.height) / 2
                } else if (this.translateY < 0 - ((this.zoom - 1) * this.canvas.height) / 2) {
                    this.translateY = 0 - ((this.zoom - 1) * this.canvas.height) / 2
                }

                this.redrawExterior()
            }
        },
        panendPanCancelEvent(e) {
            e.preventDefault()

            this.dragging = false
            this.redrawExterior()
        },
        pinchStartEvent(e) {
            e.preventDefault()

            this.pinchzoom = this.zoom
            this.pinchTranslateX = this.translateX
            this.pinchTranslateY = this.translateY
            this.zooming = true
        },
        pinchMoveEvent(e) {
            e.preventDefault()
            this.zoomCount++
            this.zoom = this.pinchzoom * e.scale

            if (this.zoom < 1) {
                this.zoom = 1
            } else if (this.zoom > 7) {
                this.zoom = 7
            }

            if (e.scale < 1) {
                if (this.zoom === 1) {
                    this.translateX = 0
                    this.translateY = 0
                } else {
                    this.translateX = this.pinchTranslateX * ((this.zoom - 1) / (this.pinchzoom - 1))
                    this.translateY = this.pinchTranslateY * ((this.zoom - 1) / (this.pinchzoom - 1))
                }
            }

            this.redrawExterior()
        },
        pinchendPinchCancelEvent(e) {
            e.preventDefault()
            this.zooming = false
            this.redrawExterior()
        },
        // Functions
        /**
         *
         * @param fullscreen
         */
        preloadImages(fullscreen = false) {
            this.resizeCanvas()

            for (let r = 1; r <= 2; r++) {
                this.images.low[r] = {}

                for (let i = 1; i <= this.totalImages; i++) {
                    this.images.low[r][i] = document.createElement('img')
                    this.images.low[r][i].onload = () => {
                        this.loadedImages++
                        if (this.loadedImages === 1 || this.loadedImages >= (this.totalImages * 2)) {
                            this.redrawExterior()
                        } else if (this.loadedImages === 9) {
                            this.moveToExt(9, 0, 0, 1, 1, true)
                        }
                    }

                    let imageWidth = 1500

                    if (!fullscreen) {
                        const canvasWidth = this.canvas.offsetWidth
                        if (canvasWidth < 450) {
                            imageWidth = 450
                        } else if (canvasWidth < 850) {
                            imageWidth = 850
                        } else if (canvasWidth < 1250) {
                            imageWidth = 1200
                        }
                    }

                    const fileKey = 'low/' + r + i.toString().padStart(2, '0') + '.jpg'
                    this.images.low[r][i].src = this.generatePhotoUrl(fileKey, imageWidth)
                }
            }
        },
        /**
         *
         */
        redrawExterior() {
            let imageWidth
            let imageHeight
            let redrawImage
            let imageSize = 'low'
            if (this.context !== 0) {
                this.resizeCanvas()

                if (this.container.offsetWidth / 3 < this.container.offsetHeight / 2) {
                    imageWidth = this.container.offsetWidth
                    imageHeight = (this.container.offsetWidth / 3) * 2
                } else {
                    imageWidth = (this.container.offsetHeight / 2) * 3
                    imageHeight = this.container.offsetHeight
                }

                if (this.currentType === 1) {
                    redrawImage = this.currentImage
                } else {
                    redrawImage = this.currentImage + 18
                }

                this.context.save()

                if (this.images.low[this.currentRow][redrawImage]) {
                    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)

                    const imageX = 0 - (((this.zoom - 1) * imageWidth) / 2) + ((this.container.offsetWidth - imageWidth) / 2)
                    const imageY = 0 - (((this.zoom - 1) * imageHeight) / 2) + ((this.container.offsetHeight - imageHeight) / 2)

                    this.context.setTransform(1, 0, 0, 1, this.translateX, this.translateY)

                    this.context.drawImage(this.images.low[this.currentRow][redrawImage], imageX, imageY, imageWidth * this.zoom, imageHeight * this.zoom)
                } else {
                    console.warn('error > ' + this.currentRow + ' - ' + redrawImage)
                }

                if (this.zoom > 1) {
                    if (this.zoom > 1 && this.zoom < 4) {
                        imageSize = 200
                    } else {
                        imageSize = 400
                    }

                    const blockSize = (imageWidth * this.zoom) / 15
                    const zoomWidth = imageWidth * this.zoom
                    const zoomHeight = imageHeight * this.zoom

                    let startX = Math.ceil((((zoomWidth - this.canvas.width) / 2) - this.translateX) / blockSize)
                    const stopX = Math.ceil(((((zoomWidth - this.canvas.width) / 2) - this.translateX) + this.canvas.width) / blockSize)
                    let startY = Math.ceil((((zoomHeight - this.canvas.height) / 2) - this.translateY) / blockSize)
                    const stopY = Math.ceil(((((zoomHeight - this.canvas.height) / 2) - this.translateY) + this.canvas.height) / blockSize)

                    if (startX === 0) {
                        startX = 1
                    }

                    if (startY === 0) {
                        startY = 1
                    }

                    for (let row = startY; row <= stopY; row++) {
                        for (let col = startX; col <= stopX; col++) {
                            let imageNr = (row - 1) * 15 + col

                            if (redrawImage < 10) {
                                imageNr = this.currentRow + '0' + redrawImage + '_' + imageNr
                            } else {
                                imageNr = this.currentRow + '' + redrawImage + '_' + imageNr
                            }

                            const imageX = (((col - 1) * blockSize) - ((zoomWidth - imageWidth) / 2)) + ((this.container.offsetWidth - imageWidth) / 2)
                            const imageY = (((row - 1) * blockSize) - ((zoomHeight - imageHeight) / 2)) + ((this.container.offsetHeight - imageHeight) / 2)
                            if (this.images[imageSize][imageNr]) {
                                if (this.images[imageSize][imageNr].customData.loaded) {
                                    this.context.drawImage(this.images[imageSize][imageNr], imageX, imageY, blockSize, blockSize)
                                } else {
                                    this.images[imageSize][imageNr].customData.zoomCount = this.zoomCount
                                    this.images[imageSize][imageNr].customData.blocksize = blockSize
                                }
                            } else {
                                if (!this.dragging && !this.zooming) {
                                    const newImage = document.createElement('img')
                                    newImage.customData = {
                                        imagesize: imageSize,
                                        imagenr: imageNr,
                                        zoomcount: this.zoomCount,
                                        blocksize: blockSize,
                                        x: imageX,
                                        y: imageY
                                    }

                                    const self = this
                                    newImage.onload = function () {
                                        this.customData.loaded = true

                                        self.images[this.customData.imagesize][this.customData.imagenr] = this

                                        self.context.save()
                                        self.context.setTransform(1, 0, 0, 1, self.translateX, self.translateY)
                                        self.context.drawImage(this, this.customData.x, this.customData.y, this.customData.blocksize, this.customData.blocksize)
                                        self.context.restore()
                                    }

                                    const fileKey = 'high/' + imageNr + '.jpg'
                                    newImage.src = this.generatePhotoUrl(fileKey, imageWidth)
                                }
                            }
                        }
                    }
                }
                this.context.restore()
            }
        },
        /**
         *
         * @param fileKey
         * @param imageWidth
         * @returns {string}
         */
        generatePhotoUrl(fileKey, imageWidth) {
            let path
            if (this.isCloudfront) {
                path = btoa(JSON.stringify({
                    bucket: this.bucket,
                    key: `${this.pathPrefix}/${this.licensePlate}/${fileKey}`,
                    edits: {
                        resize: {
                            width: imageWidth,
                            fit: 'cover'
                        }
                    }
                }))
            } else {
                path = `${this.pathPrefix}/${this.licensePlate}/${fileKey}`
            }

            return `${this.baseUrl}/${path}`
        },
        /**
         *
         */
        resizeCanvas() {
            this.canvas.width = this.container.offsetWidth
            this.canvas.height = this.container.offsetHeight
        },
        /**
         *
         * @param position
         * @param x
         * @param y
         * @param newZoom
         * @param doors
         * @param initialLoad
         * @returns {boolean}
         */
        moveToExt(position, x, y, newZoom, doors, initialLoad) {
            const oldZoomTimer = setInterval(() => {
                if (this.zoom === 1) {
                    clearInterval(oldZoomTimer)

                    let right = this.imageCount - this.currentImage + position
                    let left = this.currentImage - position
                    if (position > this.currentImage) {
                        right = position - this.currentImage
                        left = this.imageCount - position + this.currentImage
                    }

                    const positionTimer = setInterval(() => {
                        if (this.currentImage === position) {
                            clearInterval(positionTimer)

                            if (this.currentType !== doors) {
                                this.$doorsToggle()
                            }

                            const newTranslateX = x * this.canvas.width
                            const newTranslateXstep = newTranslateX / (newZoom - this.zoom)
                            const newTranslateY = y * this.canvas.height
                            const newTranslateYstep = newTranslateX / (newZoom - this.zoom)

                            const newZoomTimer = setInterval(() => {
                                if (this.zoom === newZoom && this.translateX === newTranslateX && this.translateY === newTranslateY) {
                                    clearInterval(newZoomTimer)
                                } else {
                                    if (this.zoom !== newZoom) {
                                        this.zoomCount++
                                        this.zoom++

                                        if (this.translateX !== newTranslateX) {
                                            if (newTranslateX < this.translateX) {
                                                this.translateX += newTranslateXstep

                                                if (newTranslateX > this.translateX) {
                                                    this.translateX = newTranslateX
                                                }
                                            } else {
                                                this.translateX += newTranslateXstep

                                                if (newTranslateX < this.translateX) {
                                                    this.translateX = newTranslateX
                                                }
                                            }
                                        }

                                        if (this.translateY !== newTranslateY) {
                                            if (newTranslateY < this.translateY) {
                                                this.translateY += newTranslateYstep

                                                if (newTranslateY > this.translateY) {
                                                    this.translateY = newTranslateY
                                                }
                                            } else {
                                                this.translateY += newTranslateYstep

                                                if (newTranslateY < this.translateY) {
                                                    this.translateY = newTranslateY
                                                }
                                            }
                                        }
                                    } else {
                                        this.translateX = newTranslateX
                                        this.translateY = newTranslateY
                                    }
                                    this.redrawExterior()
                                    if (!initialLoad) {
                                        this.$emit('onMoveToEvent')
                                    }
                                }
                            }, 50)
                        } else {
                            if (right < left) {
                                this.currentImage++
                                if (this.currentImage > this.imageCount) {
                                    this.currentImage = 1
                                }
                            } else {
                                this.currentImage--
                                if (this.currentImage <= 0) {
                                    this.currentImage = this.imageCount
                                }
                            }
                            this.redrawExterior()
                            if (!initialLoad && this.currentImage === position) {
                                this.$emit('onMoveToEvent')
                            }
                        }
                    }, 50)
                } else {
                    this.$zoomOut()
                }
            }, 50)
            this.redrawExterior()
            return true
        },
        toggleFullscreen() {
            if (document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                    this.resizeCanvas();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                    this.resizeCanvas();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                    this.resizeCanvas();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                    this.resizeCanvas();
                }
            } else {
                if (this.container.requestFullscreen) {
                    this.container.requestFullscreen();
                    this.resizeCanvas();
                } else if (this.container.webkitRequestFullscreen) {
                    this.container.webkitRequestFullscreen();
                    this.resizeCanvas();
                } else if (this.container.mozRequestFullScreen) {
                    this.container.mozRequestFullScreen();
                    this.resizeCanvas();
                } else if (this.container.msRequestFullscreen) {
                    this.container.msRequestFullscreen();
                    this.resizeCanvas();
                }
            }
            this.redrawExterior();
        },
        /// //////////
        // Events //
        /// /////////
        /**
         *
         */
        $doorsToggle() {
            if (this.currentType === 1) {
                this.currentType = 2
            } else {
                this.currentType = 1
            }
            this.redrawExterior()
            this.$emit('onDoorsToggledCallback', this.currentType)
        },
        /**
         *
         */
        $doorsOpen() {
            this.currentType = 2
            this.redrawExterior()
            this.$emit('onDoorsOpenedCallback', this.currentType)
        },
        /**
         *
         */
        $doorsClose() {
            this.currentType = 1
            this.redrawExterior()
            this.$emit('onDoorsClosedCallback', this.currentType)
        },
        /**
         *
         */
        $tiltToggle() {
            this.currentRow = this.currentRow === 1 ? this.currentRow = 2 : this.currentRow = 1
            this.redrawExterior()
            this.$emit('onTiltToggleCallback', this.currentRow)
        },
        /**
         *
         */
        $tiltUp() {
            this.currentRow = 2
            this.redrawExterior()
            this.$emit('onTiltUpCallback', this.currentRow)
        },
        /**
         *
         */
        $tiltDown() {
            this.currentRow = 1
            this.redrawExterior()
            this.$emit('onTiltDownCallback', this.currentRow)
        },
        /**
         *
         */
        $zoomReset() {
            this.zoom = 1
            this.redrawExterior()
            this.$emit('onZoomResetCallback', this.currentRow)
        },
        /**
         *
         */
        $zoomIn() {
            this.zoomCount++
            this.zoom++

            if (this.zoom > 7) {
                this.zoom = 7
            }
            this.redrawExterior()
            this.$emit('onZoomInCallback', this.currentRow)
        },
        /**
         *
         */
        $zoomOut() {
            this.zoomCount++
            this.zoom--
            if (this.zoom < 1) {
                this.zoom = 1
            }

            if (this.zoom === 1) {
                this.translateX = 0
                this.translateY = 0
            } else {
                this.translateX = this.translateX / 2
                this.translateY = this.translateY / 2
            }

            this.redrawExterior()
            this.$emit('onZoomOutCallback', this.currentRow)
        },
        /**
         *
         * @param position
         * @param x
         * @param y
         * @param zoom
         * @param doors
         */
        $moveTo(position, x, y, zoom, doors) {
            if (parseInt(position) > this.imageCount || Number.isNaN(parseInt(position))) {
                console.warn('position is > than current image count or is not properly set')
            } else {
                this.moveToExt(parseInt(position), x, y, zoom, doors, false)
            }
        },
        $redraw() {
            this.redrawExterior()
            this.$emit('onRedrawCallback')
        },
        $toggleFullscreen() {
            this.toggleFullscreen()
            this.$emit('onToggleFullScreenCallback')
        }
    }
}
</script>

<style lang="scss">
.pano-container {
    position: relative;

    > canvas {
        touch-action: none;
        user-select: none;
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
}
</style>
