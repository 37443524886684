<template>
    <d-modal v-if="modal.open" @close="closeHandler">
        <d-modal-header>
            <d-modal-title>Process opties</d-modal-title>
        </d-modal-header>
        <d-modal-body>
            <button class="btn btn-primary" @click="startExterior">
                <i class="material-icons">camera_alt</i>
                Maak exterieur foto's
            </button>
            <br/> <br/>
            <button class="btn btn-danger" @click="deleteProcess"><i class="material-icons">delete</i>
                Process verwijderen
            </button>
            <br/><br/>
            <button class="btn btn-primary" @click="openViewerExterior360"><i class="material-icons">info</i>Exterieur
                360
            </button>
        </d-modal-body>
    </d-modal>
</template>

<script>
import {isLicensePlate} from "@/utils/validator";

export default {
    props: {
        modal: {
            required: true
        }
    },
    data() {
        return {
            form: {
                licensePlate: {
                    validations: {
                        isLicensePlate
                    }
                }
            },
        }
    },
    methods: {
        openViewerExterior360() {
            this.closeHandler()
            this.$emit('openExteriorHandler', this.modal.open)
        },
        async deleteProcess() {
            await this.$store.dispatch('photoStudio.studio/deleteProcess', {licensePlate: this.modal.vehicle.licenseplate})
            this.$emit('deleteProcess')
            this.closeHandler()
        },
        async startExterior() {
            try {
                await this.$store.dispatch('photoStudio.studio/addProcess', {licensePlate: this.modal.vehicle.licenseplate})
                this.closeHandler()
            } catch (e){

            }
        },
        closeHandler() {
            this.modal.open = false
            this.$emit('closeHandler', this.modal.open)
        }
    }
}
</script>
